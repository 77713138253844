import React, { useEffect, useRef, useState } from 'react';
import './GoogleMap.css';
import { addMapScript, vehicleDeatilsLoader } from '../../common/common';
import toast from 'react-hot-toast';
import { googleMapId } from '../../config';
import { useParams } from 'react-router-dom';



const GoogleMap = ({initialVehicleDetail}) => {
    const mapRef = useRef(null);
    const [vehicleDetail, setVehicleDetail] = useState(initialVehicleDetail);
    let { vehicleId } = useParams();
    let watchPositionId;
    let intervalId;

    const getLastestVehicleDetails = async () => {
        const data = await vehicleDeatilsLoader({
            params: {
                vehicleId,
            },
        });

        if (data) {
            setVehicleDetail(data);
        }
    };

    // Callback function to initialize the map
    window.initMap = async () => {
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

        const NORTH_AMERICA_BOUNDS = {
            north: 71.3875,
            south: 7.1971,
            west: -172.8697,
            east: -48.9311,
        };
        // Setup initial viewpoint
        const centerOfNorthAmerica = { lat: vehicleDetail.LATITUDE, lng: vehicleDetail.LONGITUDE };

        mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 10,
            mapId: googleMapId,
            center: centerOfNorthAmerica,
            restriction: {
                latLngBounds: NORTH_AMERICA_BOUNDS,
                strictBounds: true,
            },
            mapTypeControl: false
        });

        


        mapRef.current.addListener("click", (event) => {
            const mapVehicleInfoElements = document.querySelectorAll('.map-vehicle-info');
            mapVehicleInfoElements.forEach(element => {
                element.classList.remove('map-vehicle-info');
            });
        });

        mapRef.current.vehicleMarker = new AdvancedMarkerElement({
            map: mapRef.current,
            position: { lat: vehicleDetail.LATITUDE, lng: vehicleDetail.LONGITUDE },
            content: buildContent()
        });

        intervalId = setInterval(async ()=>{
            getLastestVehicleDetails();
        }, 5 * 60 * 1000)



        mapRef.current.vehicleMarker.addListener("click", () => {
            toggleMarkerView(mapRef.current.vehicleMarker);
        });


        if (navigator.geolocation) {
            watchPositionId = navigator.geolocation.watchPosition(showUserLocationInMap, showLocationAccessError);
        } else {
            toast.error("Location Unavailable","Geolocation is not supported by this browser.");
        }

        async function showUserLocationInMap(position) {
            let positionLatLng = { lat: position.coords.latitude, lng: position.coords.longitude }
            if(mapRef.current && mapRef.current.userMarker){
                mapRef.current.userMarker.position = positionLatLng;
            } else{
                mapRef.current.userMarker = new AdvancedMarkerElement({
                    map: mapRef.current,
                    position: positionLatLng,
                    content: buildUserLocationMarkerHtml()
                });
                
                if(mapRef.current && mapRef.current.vehicleMarker){
                    let bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(positionLatLng);
                    bounds.extend(mapRef.current.vehicleMarker.position);
    
                    mapRef.current.fitBounds(bounds);
    
                    if (mapRef.current.getZoom() > 15) {
                        mapRef.current.setZoom(15);
                    }
                }
            }
        }

        
        function showLocationAccessError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    toast.error("Please allow location access in your browser settings to see your position in map.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    toast.error("Your location is unavailable.");
                    break;
                case error.TIMEOUT:
                    toast.error("The request to get your location timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    toast.error("An unknown error occurred while accessing your location.");
                    break;
            }
        }


        function toggleMarkerView(marker) {
            if (marker.content.classList.contains("map-vehicle-info")) {
                marker.content.classList.remove("map-vehicle-info");
            } else {
                marker.content.classList.add("map-vehicle-info");
                document.querySelector('.map-vehicle-info').parentElement.parentElement.style.zIndex = 99;
            }
        }



        function buildUserLocationMarkerHtml() {
            const content = document.createElement("div");
            content.innerHTML = `
                <div class="pulser">
                </div>
            `;
            return content;
        }


        function buildContent(){
            const content = document.createElement("div");
            content.classList.add("vehicleAdvMrk");
            content.innerHTML = `
                    <div class="icon">
                        <svg fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px" height="48px" viewBox="-3.14 -3.14 37.73 37.73" xml:space="preserve" stroke="#fff" stroke-width="0.00031445" transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.06289"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M7.592,16.86c-1.77,0-3.203,1.434-3.203,3.204s1.434,3.204,3.203,3.204c1.768,0,3.203-1.434,3.203-3.204 S9.36,16.86,7.592,16.86z M7.592,21.032c-0.532,0-0.968-0.434-0.968-0.967s0.436-0.967,0.968-0.967 c0.531,0,0.966,0.434,0.966,0.967S8.124,21.032,7.592,21.032z"></path> <path d="M30.915,17.439l-0.524-4.262c-0.103-0.818-0.818-1.418-1.643-1.373L27.6,11.868l-3.564-3.211 c-0.344-0.309-0.787-0.479-1.249-0.479l-7.241-0.001c-1.625,0-3.201,0.555-4.468,1.573l-4.04,3.246l-5.433,1.358 c-0.698,0.174-1.188,0.802-1.188,1.521v1.566C0.187,17.44,0,17.626,0,17.856v2.071c0,0.295,0.239,0.534,0.534,0.534h3.067 c-0.013-0.133-0.04-0.26-0.04-0.396c0-2.227,1.804-4.029,4.03-4.029s4.029,1.802,4.029,4.029c0,0.137-0.028,0.264-0.041,0.396 h8.493c-0.012-0.133-0.039-0.26-0.039-0.396c0-2.227,1.804-4.029,4.029-4.029c2.227,0,4.028,1.802,4.028,4.029 c0,0.137-0.026,0.264-0.04,0.396h2.861c0.295,0,0.533-0.239,0.533-0.534v-1.953C31.449,17.68,31.21,17.439,30.915,17.439z M20.168,12.202l-10.102,0.511L12,11.158c1.051-0.845,2.357-1.305,3.706-1.305h4.462V12.202z M21.846,12.117V9.854h0.657 c0.228,0,0.447,0.084,0.616,0.237l2.062,1.856L21.846,12.117z"></path> <path d="M24.064,16.86c-1.77,0-3.203,1.434-3.203,3.204s1.434,3.204,3.203,3.204c1.769,0,3.203-1.434,3.203-3.204 S25.833,16.86,24.064,16.86z M24.064,21.032c-0.533,0-0.967-0.434-0.967-0.967s0.434-0.967,0.967-0.967 c0.531,0,0.967,0.434,0.967,0.967S24.596,21.032,24.064,21.032z"></path> </g> </g> </g></svg>
                    </div>
                    <div class="details">
                        <div class="map-vehicle-heading">
                            <h3>${vehicleDetail.VehicleName}</h3>
                        </div>
                        <ul>
                            <li><strong>Device Number:</strong> ${vehicleDetail.ID}</li>
                            <li><strong>Address:</strong> ${vehicleDetail.Address}</li>
                            <li><strong>Speed:</strong> ${vehicleDetail.Speed}</li>
                            <li><strong>Power Source:</strong> ${vehicleDetail.PowerSource}</li>
                        </ul>
                    </div>
            `;
            return content;
        }
    };

    useEffect(() => {
        if (!window.google) {
            addMapScript();
        } else {
            window.initMap();
        }

        return () => {
            if (watchPositionId) {
                navigator.geolocation.clearWatch(watchPositionId);
            }

            if(intervalId){
                clearInterval(intervalId);
            }

            // Clean up the map when the component is unmounted
            if (mapRef.current) {
                mapRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if(mapRef.current && mapRef.current.vehicleMarker){
            mapRef.current.vehicleMarker.position = { lat: vehicleDetail.LATITUDE, lng: vehicleDetail.LONGITUDE };
        }
    }, [vehicleDetail]);

    return (
        <>
            <div id="map" className='h-full w-full'>

            </div>
        </>
    );
};

export default GoogleMap;
