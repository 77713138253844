import React from 'react'
import { Oval } from 'react-loader-spinner'

function Loader({loading, blankScreen, halfScreen, heightClass}) {
    if(!heightClass){
        heightClass = "min-h-80"
    }

    if(halfScreen){
        return (
            <div className={`py-4 flex justify-center items-center ${heightClass}`}>
                <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
            </div>
        )
    }

    return (
        <>
            {loading ? <div className={`overlay ${blankScreen ? 'blankScreen':''}`}>
                <Oval
                    visible={true}
                    height="160"
                    width="160"
                    color="#4fa94d"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="spinner-wrapper"
                />
            </div> : ""}
        </>
    )
}

export default Loader