import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WebRequest from '../../WebRequest';
import toast from 'react-hot-toast';
import Header from '../../Components/Header/Header';
import Loader from '../../Components/Loader/Loader';


const ReportAnIssue = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailDisabled, setEmailDisabled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const reponseData = await WebRequest.CheckAuthentication();
            if(reponseData.data.email && reponseData.data.email.length > 0){
                setEmail(reponseData.data.email);
                setEmailDisabled(true)
            }
        }

        checkAuth();
    }, []);

    const submitForm = async (event) =>{
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.target);
        formData.set('emailAddress', email);
        const response = await WebRequest.ReportAnIssue(formData);
        setLoading(false);
        if(response.success){
            toast.success(response.message);
            navigate("/");
            return response;
        } else{
            toast.error(response.message);
            return response;
        }
    }

    return (
        <div className="dvh-full" id='reportAnIssue'>
            <Loader loading={loading}/>
            <div className='flex flex-col h-full'>
                <Header title={"Report An Issue"}/>
                <div className="p-2 m-3 text-bg-light text-center rounded bg-white">
                    Report app-related issue only. Your issue will be directly sent to "<b>IIMSWISS Corp.</b>"
                </div>
                <form className="flex flex-col h-100 flex-1" method='post' encType="multipart/form-data" onSubmit={submitForm}>
                    <div className="flex flex-1 justify-center px-5">
                        <div className="flex flex-col gap-3 w-full">
                            <div>
                                <label htmlFor="emailAddress">Email Address *</label>
                                <input type="email" value={email} disabled={emailDisabled} onChange={(e)=>setEmail(e.target.value)} name='emailAddress' id="emailAddress" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-0 block w-full p-2.5 disabled:bg-gray-100" placeholder="example@iimswiss.com" required />
                            </div>
                            <div className="flex-1 flex flex-col">
                                <label htmlFor="rai-description">Describe the issue in detail *</label>
                                <textarea minLength={20} name='description' className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-0 flex-1" placeholder="Provide detailed information about the issue" id="rai-description" required></textarea>
                            </div>
                            <div>
                                <label htmlFor="rai-Files">Attach Screenshots</label>
                                <input name='files' className="block w-full text-gray-900 border border-gray-300 cursor-pointer bg-gray-50" aria-describedby="rai-file-Help" id="rai-Files" multiple type="file" />
                                <p className="mt-1 text-sm text-gray-500" id="rai-file-Help">Files will be sent to IIMSWISS for debugging.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-8 center">
                        <button type='submit' className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReportAnIssue;
