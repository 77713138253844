import React, { useEffect, useRef, useState } from 'react';
import WebRequest from '../../WebRequest';
import { addMapScript, adjustDate, compareOnlyDate, debounce } from '../../common/common';
import dayjs from 'dayjs';
import './HistoryGoogleMap.css';
import { googleMapId } from '../../config';
import Loader from '../Loader/Loader';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Toast from '../Toast/Toast';

function HistoryGoogleMap({ vehicleDetails }) {
    const getStartDate = () =>{
        let dtStartDate = adjustDate(-6 * 60);
        const yesterday = new Date(-24 * 60);
        if (compareOnlyDate(yesterday, dtStartDate) === 0) {
            dtStartDate.setHours(0, 0, 0, 0);
        }

        return dtStartDate
    }

    const mapRef = useRef(null);
    const [startDate, setStartDate] = useState(getStartDate());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [noHistoryAvailable, setNoHistoryAvailable] = useState(false);
    const [noHistoryAvailableMsg, setNoHistoryAvailableMsg] = useState('');
    

    // Callback function to initialize the map
    window.initMap = async () => {
        const NORTH_AMERICA_BOUNDS = {
            north: 71.3875,
            south: 7.1971,
            west: -172.8697,
            east: -48.9311,
        };
        // Setup initial viewpoint
        const initialCenterPoint = { lat: vehicleDetails.LATITUDE, lng: vehicleDetails.LONGITUDE };

        mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 10,
            mapId: googleMapId,
            center: initialCenterPoint,
            restriction: {
                latLngBounds: NORTH_AMERICA_BOUNDS,
                strictBounds: true,
            },
            mapTypeControl: false,
            gestureHandling: "greedy",
            rotateControl: true
        });

        mapRef.current.addListener('heading_changed', function() {
            const currentHeading = mapRef.current.getHeading();
            const elements = document.querySelectorAll(".angle_arrow");
            for (const element of elements) {
                const angleDiffClass = element.classList.value.split(' ').find(className => className.startsWith("angleDiff-"));
                const angleClass = element.classList.value.split(' ').find(className => className.startsWith("angle-"));
                const angleDiff = parseInt(angleDiffClass.split('-')[1], 10);
                let newAngle = parseInt(angleDiff - currentHeading);
                if(newAngle > 360){
                    newAngle = newAngle - 360;
                }
                if(newAngle < 0){
                    newAngle = newAngle + 360;
                }
                element.classList.remove(angleClass); // Remove the old class
                element.classList.add(`angle-${newAngle}`); // Add the new class
            }
        });


        getHistory();
    };

    useEffect(() => {
        if (!window.google) {
            addMapScript();
        } else {
            window.initMap();
        }

        return () => {
            // Clean up the map when the component is unmounted
            if (mapRef.current) {
                mapRef.current = null;
            }
        };
    }, []);


    function buildContent(angle) {
        const content = document.createElement("div");
        content.classList.add("angle-arrow-wrapper");
        content.innerHTML = `
                <svg class="angle_arrow angle-${angle} angleDiff-${angle}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
        `;
        return content;
    }

    function buildInfoWindowContent(vehicleHistory) {
        // Convert timestamp to Date object
        const reportTime = new Date(vehicleHistory.timestamp);
        const formattedReportTime = `${reportTime.toLocaleDateString()} ${reportTime.toLocaleTimeString()}`;
        let content = `<div>
                        <div class="info-window-heading">
                            <h3>${vehicleHistory.VehicleName}</h3>
                            <button onclick="closeInfoWindow()" class="material-symbols-outlined">close</button>
                        </div>
                        <div class="reportTime">Report Time: ${formattedReportTime}</div><table class="info-window-table">`;
        content += '<tbody>';

        const propertiesToShow = ['Address', 'Speed', 'PowerSource'];

        for (const property of propertiesToShow) {
            if (vehicleHistory.hasOwnProperty(property)) {
                content += `<tr class='border-b'>
                                <td class='p-2 coloumBold'><strong>${property}</strong></td>
                                <td class='p-2'>${vehicleHistory[property]}</td>
                            </tr>`;
            }
        }


        content += `<tr class='border-b'>
            <td class='p-2 coloumBold'><strong>Report Type</strong></td>
            <td class='p-2'>${vehicleHistory.vcReportType}</td>
        </tr>`

        content += '</tbody>';
        content += '</table></div>';
        return content;
    }

    const getHistory = async (newStartDate, newEndDate) => {
        setLoading(true);
        const vehicleId = vehicleDetails.ID;
        let data;
        if (newStartDate && newEndDate) {
            data = { startDate: newStartDate, endDate: newEndDate, vehicleId }
        } else {
            data = { startDate, endDate, vehicleId }
        }
        const historyData = await WebRequest.GetVehicleHistory(data);
        if (historyData && historyData.success && historyData.data.dtVehicleHistory && historyData.data.dtVehicleHistory.length > 0) {
            let dtVehicleHistory = historyData.data.dtVehicleHistory;
            await showHistory(dtVehicleHistory);
        } else {
            if(historyData.success){
                setNoHistoryAvailableMsg("No History Available in given time frame");
            } else{
                setNoHistoryAvailableMsg(historyData.message);
            }
            await showHistory(null);
        }
        setLoading(false);
    }

    const debouncedGetHistory = useRef(
        debounce((newStartDate, newEndDate) => {
            getHistory(newStartDate, newEndDate);
        }, 500)
    ).current;


    const showHistory = async (dtVehicleHistory) => {
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        if (mapRef.current && mapRef.current.polyline) {
            mapRef.current.polyline.setMap(null);
        }

        if (mapRef.current && mapRef.current.historyMrks) {
            mapRef.current.historyMrks.forEach(marker => marker.setMap(null));
            mapRef.current.historyMrks = null;
        }
        if (dtVehicleHistory) {
            let polyCords = [];
            const bounds = new window.google.maps.LatLngBounds();
            for (var vehicleHistory of dtVehicleHistory) {
                let marker = new AdvancedMarkerElement({
                    map: mapRef.current,
                    position: { lat: vehicleHistory.LATITUDE, lng: vehicleHistory.LONGITUDE },
                    content: buildContent(vehicleHistory.ANGLE)
                });


                const infoWindowContent = buildInfoWindowContent(vehicleHistory);
                marker.addListener("click", () => {
                    if (mapRef.current.infoWindow) {
                        mapRef.current.infoWindow.close();
                    }

                    const infoWindow = new window.google.maps.InfoWindow({
                        content: infoWindowContent,
                        maxWidth: 320
                    });
                    infoWindow.open(mapRef.current, marker);

                    mapRef.current.infoWindow = infoWindow;
                });

                if (mapRef.current.historyMrks) {
                    mapRef.current.historyMrks.push(marker);
                } else {
                    mapRef.current.historyMrks = [marker];
                }
                polyCords.push({ lat: vehicleHistory.LATITUDE, lng: vehicleHistory.LONGITUDE });
                bounds.extend(new window.google.maps.LatLng(vehicleHistory.LATITUDE, vehicleHistory.LONGITUDE));
            }

            const polyline = new window.google.maps.Polyline({
                path: polyCords,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 12,
            });
            mapRef.current.polyline = polyline;

            polyline.setMap(mapRef.current);

            // Fit map bounds to the polyline
            mapRef.current.fitBounds(bounds);
            if (mapRef.current.getZoom() > 15) {
                mapRef.current.setZoom(15);
            }
            mapRef.current.setHeading(0);
            setNoHistoryAvailable(false);
        } else {
            setNoHistoryAvailable(true);
        }
    }


    window.closeInfoWindow = () => {
        if (mapRef.current.infoWindow) {
            mapRef.current.infoWindow.close();
        }
    }


    const getForwardHistory = () => {
        // what if day is today
        var newEndDate = adjustDate(6*60, endDate);
        var newStartDate = adjustDate(6*60, startDate);
        var currentDt = new Date();
        if(newEndDate.getTime() > currentDt.getTime()){
            newEndDate = currentDt;
            newStartDate = adjustDate(-6*60, currentDt);
            var yesterday = adjustDate(-24*60, currentDt);
            if(compareOnlyDate(yesterday, newStartDate) == 0){
                newStartDate = new Date(currentDt.getFullYear(), currentDt.getMonth(), currentDt.getDate(), 0, 0, 0);
            }
        } else{
            // If Date changes
            if(compareOnlyDate(newEndDate, endDate) == 1 && endDate.getHours() != 23 && endDate.getMinutes() != 59){
                newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 0);
                var timeDiff = newEndDate.getTime() - endDate.getTime();
                newStartDate = new Date(startDate.getTime() + timeDiff);
            }
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        debouncedGetHistory(newStartDate, newEndDate);
    }

    const getBackwardHistory = () => {
        var newStartDate = adjustDate(-6*60, startDate);
        var newEndDate;
        if(compareOnlyDate(startDate, newStartDate) == 1){
            var adjustedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
            if(adjustedStartDate.getTime() == startDate.getTime()){
                newStartDate = adjustDate(-6*60,adjustedStartDate);
                newEndDate = adjustDate(-1,adjustedStartDate);
            } else{
                newStartDate = adjustedStartDate;
                var timeDiff = startDate.getTime() - adjustedStartDate.getTime();
                newEndDate = new Date(endDate.getTime() - timeDiff);
            }
        } else{
            newEndDate = adjustDate(-6*60, endDate);
        }
        setEndDate(newEndDate);
        setStartDate(newStartDate);
        debouncedGetHistory(newStartDate, newEndDate);
    }

    const startDateChanged = (e) => {
        const newDate = new Date(e);
        const newStartDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        let newEndDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
        const currentDt = new Date();
    
        if (newEndDate.getTime() > currentDt.getTime()) {
            newEndDate = currentDt;
            newStartDate = adjustDate(-6 * 60, currentDt);
    
            const yesterday = new Date(currentDt.getTime() - 24 * 60 * 60 * 1000);
            if (compareOnlyDate(yesterday, newStartDate) === 0) {
                newStartDate.setHours(0, 0, 0, 0);
            }
        }
    
        setEndDate(newEndDate);
        setStartDate(newStartDate);
        getHistory(newStartDate, newEndDate);
    }
    

    return (
        <>
            <Loader loading={loading} />
            <div id="map" className="w-full" style={{ height: 'calc(100% - 72px)'}}>

            </div>
            {noHistoryAvailable ? 
                <Toast 
                    message={noHistoryAvailableMsg} 
                    containerStyle={{display: 'flex', justifyContent:"center", alignItems: 'center'}} 
                    toastStyle={{backgroundColor: "#fff", boxShadow:"0px 0px 5px 0px black"}}
                    onClose={()=>{setNoHistoryAvailable(false)}}/>
                : '' }
            <div className='divHistoryPanelContainer center'>
                <div className='divHistoryPanel bg-white'>
                    <button onClick={getBackwardHistory} className='material-symbols-outlined text-blue-600'>arrow_back_ios</button>
                    <div className='divDate' onClick={() => document.querySelector(".selectDate-picker").click()}>
                        <span className='flex-1 text-center text-blue-600'>
                            <span className='font-semibold'>
                                {new Date(startDate).toLocaleString('en-US', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: "numeric"
                                })} 
                            </span>
                            <br/>
                            <span>
                            {new Date(startDate).toLocaleString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })} to  {" "}
                            {new Date(endDate).toLocaleString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })}
                            </span>
                        </span>
                    </div>
                    <button type='button' onClick={getForwardHistory} className='material-symbols-outlined text-blue-600'>arrow_forward_ios</button>
                </div>
            </div>
            <div className='hidden'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker 
                        disableFuture
                        disableHighlightToday
                        className='selectDate-picker' 
                        value={dayjs(startDate)} 
                        onAccept={startDateChanged} 
                        label="Select Start Date"/>
                </LocalizationProvider>
            </div>
        </>
    );
}

export default HistoryGoogleMap