import React, { useEffect, useState } from 'react';
import WebRequest from '../../WebRequest';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CheckAuthentication, NKTEN, checkUpdates } from '../../common/common';
import Loader from '../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { changeSettings } from '../../features/usersettings/usersettingsSlice';

function PinLogin() {
    const dispatch = useDispatch();
    const [preLoading, setPreLoading] = useState(true);


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState("");
    const [isLoginButtonPinDisabled, setIsLoginButtonPinDisabled] = useState(true);


    const enabledDisableLoginBtn = () => {
        if (pin.length > 0) {
            setIsLoginButtonPinDisabled(false);
        } else {
            setIsLoginButtonPinDisabled(true);
        }
    }


    useEffect(() => {
        const checkAuth = async () => {
            const isAuthenticatedUser = await CheckAuthentication();
            if (isAuthenticatedUser) {
                navigate("/");
            }
            setPreLoading(false);
        }

        checkAuth();
        enabledDisableLoginBtn();
    }, []);


    useEffect(() => {
        enabledDisableLoginBtn();
    }, [pin]);



    const loginAsMehanic = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await WebRequest.Login({
            "pin": pin
        })
        setLoading(false);
        if (response.success) {
            localStorage.setItem(NKTEN, response.data.token);
            dispatch(changeSettings({ historyAccess: response.data.historyAccess, username: response.data.username, userType: response.data.userType, hasRfidCard: response.data.hasRfidCard }))
            navigate('/');
        } else {
            dispatch(changeSettings({ historyAccess: false, username: null, userType: null, hasRfidCard: null }))
            toast.error(response.message);
        }
    }

    return (
        preLoading ? <Loader loading={preLoading} blankScreen={true} /> :
            <div className='dvh-full flex flex-col px-6 py-12 lg:px-8 gap-4'>
                <div className="flex flex-1 flex-col justify-center">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Login
                        </h2>
                    </div>

                    {loading ? (<Loader halfScreen={true} loading={loading} />) : (<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">

                        <form className="space-y-6" onSubmit={loginAsMehanic}>
                            <div>
                                <label htmlFor="pin" className="block text-sm font-medium leading-6 text-gray-900">
                                    Enter PIN
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="pin"
                                        name="pin"
                                        type="pin"
                                        autoComplete="PIN"
                                        maxLength={6}
                                        required
                                        onPaste={(e) => setPin(e.target.value)}
                                        value={pin}
                                        onChange={(e) => setPin(e.target.value)}
                                        className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm shadow-sm"
                                    />
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-1 items-center'>
                                <Link to='/login' className='text-left text-base text-blue-600 font-semibold'>Login As User ?</Link>
                                <button
                                    type="submit"
                                    disabled={isLoginButtonPinDisabled}
                                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-300"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                        <p className="mt-8 text-center text-sm text-gray-500">
                            Facing a problem?{' '}
                        </p>
                        <div className="mt-4 text-center text-sm text-gray-500 center gap-8">
                            <Link to={'/reportAnIssue'} className="inline-block font-semibold p-2 text-blue-600 hover:text-blue-500 border border-blue-600">
                                Report an Issue
                            </Link>
                            <button
                                type="button"
                                onClick={checkUpdates}
                                className="inline-block font-semibold p-2 text-blue-600 hover:text-blue-500 border border-blue-600"
                            >
                                Check Updates
                            </button>
                        </div>
                    </div>)}

                </div>
                <div className='text-center'>
                    <h1 className='text-2xl text-blue-600 font-semibold'>StopTheft</h1>
                    <p>By IIMSWISS Corp.</p>
                </div>
            </div>
    )
}

export default PinLogin