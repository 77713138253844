import React from 'react'
import { Outlet } from 'react-router-dom'
import TopBar from './Components/TopBar/TopBar'

function AppLayout() {
    return (
        <>
            <TopBar />
            <div id='content'>
                <Outlet />
            </div>
        </>
    )
}

export default AppLayout