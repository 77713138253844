import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeTitle } from '../../features/title/titleSlice';
import './SecureLock.css'
import BottomNavigationBar from '../../Components/BottomNavigationBar/BottomNavigationBar';
import WebRequest from '../../WebRequest';
import ShowVehicleDetails from '../../Components/ShowVehicleDetails/ShowVehicleDetails';
import toast from 'react-hot-toast';
import { NAME_UNAVAILABLE, debounce, sleep, vehicleDeatilsLoader } from '../../common/common';
import { Skeleton } from '@mui/material';
import Loader from '../../Components/Loader/Loader';




function SecureLock() {
    let { vehicleId } = useParams();
    const [loading, setLoading] = useState(true);
    const [commands, setCommands] = useState(false);
    const [loadingCommands, setLoadingCommands] = useState(false);
    const [relayStatus, setRelayStatus] = useState();
    const relayStatusRef = useRef();
    const [loadingRelayStatus, setLoadingRelayStatus] = useState(false);
    const [warningStatus, setWarningStatus] = useState(false);
    let selectedVehicle = useRef();
    let sentCommand = useRef();
    const prevStatus = useRef();

    const dispatch = useDispatch();
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            selectedVehicle.current = await vehicleDeatilsLoader({ vehicleId });
            setLoading(false);
            if (selectedVehicle.current) {
                dispatch(changeTitle(selectedVehicle.current.VehicleName))
            } else {
                dispatch(changeTitle(NAME_UNAVAILABLE));
                return;
            }
            loadCommands();
            getLastStatus(true);
        };
        loadData();
    }, []);


    const getLastStatus = async (intialRun, warn = false) => {
        let returnedStatus = null;
        setLoadingRelayStatus(true);
        setWarningStatus(false);
        const apiResponse = await WebRequest.GetRelayLastStatus({ vehicleId: selectedVehicle.current.ID });
        if (apiResponse.success && apiResponse.data.relayStatus.length) {
            const lastState = prevStatus.current;
            prevStatus.current = relayStatusRef.current;

            returnedStatus = apiResponse.data.relayStatus;
            setRelayStatus(returnedStatus);
            relayStatusRef.current = returnedStatus;
            if (intialRun) {
                prevStatus.current = returnedStatus;
            }


            if (warn && lastState == returnedStatus && ((returnedStatus == "Locked" && sentCommand.current == "RelayOn") || (returnedStatus == "Unlocked" && sentCommand.current == "RelayOff"))) {
                setWarningStatus(true);
            }
        }
        setLoadingRelayStatus(false);
        return returnedStatus;
    }


    const getLastStatusWithTimeout = async () => {
        setLoadingRelayStatus(true);
        await sleep(7000);
        let returnedStatus = await getLastStatus();
        if (returnedStatus && prevStatus.current == returnedStatus) {
            setLoadingRelayStatus(true);
            await sleep(8000);
            await getLastStatus(false, true);
        }
        setLoadingCommands(false);
    }



    const debounceGetStatus = useRef(
        debounce(async () => await getLastStatus(false, true), 500)
    ).current;






    const loadCommands = async () => {
        setLoadingCommands(true);
        const GetLockUnlockCommandsData = await WebRequest.GetLockUnlockCommands({ vehicleId: selectedVehicle.current.ID });
        if (GetLockUnlockCommandsData.success && GetLockUnlockCommandsData.data.lockUnlockCommands.length > 0) {
            setCommands(GetLockUnlockCommandsData.data.lockUnlockCommands);
        }
        setLoadingCommands(false);
    }

    const getCommandByIdentifier = (commandIdentifier) => {
        if (commands) {
            var foundCommand = commands.find(function (cmd) {
                return cmd.identifier === commandIdentifier;
            });

            if (foundCommand) {
                return foundCommand.command;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const sendCommand = async (deviceId, commandIdentifier) => {
        setLoadingCommands(true);
        let command = getCommandByIdentifier(commandIdentifier);
        if (command) {
            sentCommand.current = commandIdentifier;
            const data = { deviceId, command };
            const response = await WebRequest.SendMessageToDevice(data);
            if (response.success) {
                // toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } else {
            toast.error("No Lock and Unlock Command Found");
        }
        getLastStatusWithTimeout();
    }


    const showStatus = () => {
        if (loadingRelayStatus) {
            return (
                <>
                    <div className='text-center text-black'>
                        <span className='text-xs'>Verifying</span>
                        <Skeleton variant="text" className="!h-2 w-[10rem]" />

                    </div>
                </>
            );
        }


        if (relayStatus == "Locked") {
            return (
                <>
                    <span className="material-symbols-outlined text-3xl  mr-2">
                        lock
                    </span>
                    {relayStatus}
                </>
            )
        } else if (relayStatus == "Unlocked") {
            return (
                <>
                    <span className="material-symbols-outlined text-3xl  mr-2">
                        lock_open_right
                    </span>
                    {relayStatus}
                </>
            )
        } else {
            return (
                <>
                    {relayStatus || "Unavailable"}
                </>
            )
        }
    }

    if (loading) {
        return (<>
            <Loader loading={true} halfScreen={true} heightClass={'innerContainer'} />
            <BottomNavigationBar />
        </>
        )
    }

    return (
        <>
            <div className='innerContainer max-w-5xl mx-auto'>
                <div className='h-full flex flex-col'>
                    <div className='bg-white m-4 rounded-sm'>
                        <div className="flex items-center justify-end gap-4 p-4 pb-0">
                            <div className={`center text-3xl font-semibold flex-1  relative left-7 ${warningStatus ? 'text-red-500' : ''}`}>
                                {showStatus()}
                            </div>
                            <button onClick={debounceGetStatus} className={`rounded-md text-blue-600 px-3.5 py-2 pt-4 pr-4s ${loadingRelayStatus ? 'invisible' : ''}`}>
                                <span className="material-symbols-outlined">
                                    refresh
                                </span>
                            </button>
                        </div>
                        <p className='text-red-500 text-center text-sm h-6'>{warningStatus && !loadingRelayStatus && "Tap on refresh button"}</p>
                        <div>
                            <div className="flex justify-evenly gap-4 p-4 h-32 items-center">
                                {
                                    loadingCommands ? <Loader heightClass={"h-20"} loading={true} halfScreen={true} /> : (commands == null ? <div className='text-2xl text-red-600'>Unavailable</div> :
                                        <>
                                            <button className="lock-unlock-btn" onClick={() => { sendCommand(selectedVehicle.current.ID, "RelayOn") }}>
                                                <div className="p-2">
                                                    <span className="material-symbols-outlined">
                                                        lock_open_right
                                                    </span>
                                                </div>
                                                <span className="fs-1">
                                                    Unlock
                                                </span>
                                            </button>
                                            {/*  */}
                                            <button className="lock-unlock-btn" onClick={() => { sendCommand(selectedVehicle.current.ID, "RelayOff") }}>
                                                <div className="p-2">
                                                    <span className="material-symbols-outlined">
                                                        lock
                                                    </span>
                                                </div>
                                                <span className="fs-1">
                                                    Lock
                                                </span>
                                            </button>
                                        </>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                    <div className='p-2 flex-1 flex flex-col items-center'>

                        {selectedVehicle.current ? <ShowVehicleDetails vehicle={selectedVehicle.current} /> :
                            <div className='center h-full'>Vehicle Not Found</div>}
                    </div>
                </div>
            </div>
            <BottomNavigationBar />
        </>
    )
}

export default SecureLock