import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Loader from '../Loader/Loader';
import WebRequest from '../../WebRequest';
import toast from 'react-hot-toast';

function DeleteUser({showDeleteConfirm, setShowDeleteConfirm, user, onDeleteCallback}) {
    const [loading, setLoading] = useState(false);

    const deleteUser = async (id) => {
        setLoading(true);
        const response = await WebRequest.DeleteUser({
            id
        });
        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
        setShowDeleteConfirm(false)
        setLoading(false);
        if(onDeleteCallback){
            onDeleteCallback();
        }
    }

    return (<>
        <Loader loading={loading} />
        <Dialog
            open={showDeleteConfirm}
            onClose={() => { setShowDeleteConfirm(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Delete User
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the user {user.vcUserName} with email {user.vcEmail}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setShowDeleteConfirm(false) }}>Cancel</Button>
                <Button onClick={() => { deleteUser(user.idId) }} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default DeleteUser