import { combineReducers, configureStore } from '@reduxjs/toolkit'
import titleSlice from '../features/title/titleSlice'
import vehicleListSlice from '../features/vehicles/vehiclesSlice';
import storage from 'redux-persist/lib/storage';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import usersettingsSlice from '../features/usersettings/usersettingsSlice';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    title: titleSlice,
    vehicleList: vehicleListSlice,
    userSettings: usersettingsSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        })
});

export const persistor = persistStore(store);