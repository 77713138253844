import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { CheckAuthentication } from './common/common';
import Loader from './Components/Loader/Loader';

function PrivateRoute() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
        setLoading(true);
        const isAuthenticated = await CheckAuthentication();
        setIsAuthenticated(isAuthenticated);
        setLoading(false);
    }

    checkAuth();
  }, []);

  return (
    <>
      {loading ? (
        <Loader loading={loading} blankScreen={false} halfScreen={true} heightClass={"innerContainerDvh"}/>
      ) : isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default PrivateRoute;
