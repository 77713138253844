import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};

const TIME_LIMIT = 20;

const CountdownTimer = () => {
  const timeLeft = useRef(TIME_LIMIT);
  const [timerInterval, setTimerInterval] = useState(null);
  const [leftTime, setLeftTime] = useState(TIME_LIMIT);

  useEffect(() => {
    startTimer();
    return () => clearInterval(timerInterval);
  }, []);

  const startTimer = () => {
    const interval = setInterval(() => {
      timeLeft.current = timeLeft.current - 1;
      setLeftTime(timeLeft.current);
      if (timeLeft.current === 0) {
        onTimesUp();
        clearInterval(interval);
      }
    }, 1000);
    setTimerInterval(interval);
  };

  const onTimesUp = () => {
    clearInterval(timerInterval);
  };

  const coundownFormatTime = time => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    if(minutes < 0 || seconds < 0){
      return "0:00"
    }
    
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="center h-screen">
      <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent className="center">
            <div className="border-8 border-blue-500 size-60 rounded-full center">
              <span className="text-5xl">{coundownFormatTime(leftTime)}</span>
            </div>
          </DialogContent>
        </Dialog>
      
    </div>
  );
};

export default CountdownTimer;
