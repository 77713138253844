import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';
import WebRequest from '../../WebRequest';
import Loader from '../../Components/Loader/Loader';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { adjustDate } from '../../common/common';
import { Alert } from '@mui/material';
import './MehanicPin.css'



function MechanicPin() {
    const [activePin, setActivePin] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [newExpiryDate, setNewExpiryDate] = useState(adjustDate(24 * 60, new Date()));
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const loadPin = async () => {
        setLoading(true);
        const pinData = await WebRequest.GetMechanicPin();
        if (pinData.success) {
            setActivePin(pinData.data.pin);
            setExpiry(pinData.data.expiry);
        }
        setLoading(false);
    }

    useEffect(() => {
        dispatch(changeTitle("Guest Login"));
        loadPin();
    }, []);

    const handleCreatePin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const pinData = await WebRequest.CreateMechanicPin(
            {
                expiryDate: newExpiryDate
            }
        );

        if (pinData.success) {
            setActivePin(pinData.data.pin);
            setExpiry(pinData.data.expiry);
        } else {
            toast.error(pinData.message);
        }
        setLoading(false);
    }

    const splitPinDigits = () => {
        if (activePin) {
            return activePin.toString().split('');
        }
        return [];
    }

    const expirePin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const pinData = await WebRequest.ExpireMechanicPin();
        if (pinData.success) {
            setActivePin(null);
            setExpiry(null);
            toast.success("Guest Login PIN has been expired.")
        } else {
            toast.error(pinData.message);
        }
        setLoading(false);
    };

    const formatDate = () => {
        if (expiry) {
            const date = new Date(expiry);
            return (<div>
                <div className="bg-gray-100 border max-w-56 border-gray-300 text-gray-500 text-base rounded-lg block p-4">
                    <span className="day">{date.getDate()} </span>
                    <span className="month">{date.toLocaleString('default', { month: 'long' })} </span>
                    <span className="year">{date.getFullYear()}</span>
                    <span className="hours">, {((date.getHours() + 11) % 12 + 1).toString().padStart(2, '0')}</span>
                    <span className="colon">:</span>
                    <span className="minutes">{date.getMinutes().toString().padStart(2, '0')}</span>
                    <span className="am-pm">{date.getHours() >= 12 ? ' PM' : ' AM'}</span>
                </div>
            </div>)
        } else {
            return "No Expiry Date Available"
        }
    };

    return (
        <div className="p-4 h-full flex flex-col items-center">
            {loading ? <Loader loading={loading} halfScreen={true} heightClass={'innerContainerDvh'}/> :
            (
                activePin !== null ? <div className='bg-white center flex-col gap-8 rounded-lg p-8'>
                    <h3 className='text-3xl font-semibold'>Active PIN</h3>
                    <div className="center gap-2 sm:gap-4">
                        {splitPinDigits().map((digit, index) => (
                            <span key={index} className="center bg-gray-100 font-semibold text-gray-500 shadow-md text-lg sm:text-xl size-10 sm:size-12">
                                {digit}
                            </span>
                        ))}
                    </div>
                    <div className='center flex-col gap-3'>
                        <h3 className='text-2xl font-semibold'>Expiry Date</h3>
                        <div className="text-center mb-2 text-lg font-semibold">
                            <span className="text-sm text-gray-500">{formatDate()}</span>
                        </div>
                    </div>
                    <Alert severity="warning" className='max-w-lg'>This PIN allow users to login as guest in your account. Only share when needed. Remove PIN when not in use for safety.</Alert>
                    <button
                        className="w-48 center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={expirePin}
                    >
                        Remove PIN
                    </button>
                </div>
                    :
                    <div className='bg-white flex-col gap-8 rounded-lg p-8 mt-4'>
                        <h3 className='text-3xl font-semibold text-center'>Generate New PIN</h3>
                        <Alert severity="warning" className='max-w-lg my-8'>This PIN allow users to login as guest in your account. Remove PIN when not in use for safety.</Alert>
                        <div className='mb-8 text-center'>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Select Expiry Date</label>
                            <div onClick={(e) => document.querySelector('.selectTimeDate-picker').click()} className="bg-gray-50 border max-w-56 border-gray-300 text-gray-900 text-sm rounded-lg inline-flex justify-between items-center w-full p-2.5 ">
                                <div>
                                <span className="day">{newExpiryDate.getDate()} </span>
                                <span className="month">{newExpiryDate.toLocaleString('default', { month: 'long' })} </span>
                                <span className="year">{newExpiryDate.getFullYear()}</span>
                                <span className="hours">, {((newExpiryDate.getHours() + 11) % 12 + 1).toString().padStart(2, '0')}</span>
                                <span className="colon">:</span>
                                <span className="minutes">{newExpiryDate.getMinutes().toString().padStart(2, '0')}</span>
                                <span className="am-pm">{newExpiryDate.getHours() >= 12 ? ' PM' : ' AM'}</span>
                                </div>
                                <span className="material-symbols-outlined">
                                    schedule
                                </span>
                            </div>
                        </div>
                        <div className='hidden'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    disablePast
                                    className='selectTimeDate-picker'
                                    defaultValue={dayjs(newExpiryDate)}
                                    onChange={(e) => setNewExpiryDate(new Date(e))}
                                    label="Select PIN Expiry Date"
                                    maxDate={dayjs(new Date()).add(30, 'day')}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="center">
                            <button
                                className="w-48 mt-8 center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleCreatePin}
                            >
                                Create
                            </button>
                        </div>
                    </div>
            )
            }
        </div>
    )
}

export default MechanicPin
