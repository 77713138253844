import {
    createHashRouter
} from "react-router-dom";
import Home, { loader as vehiclesDataLoader } from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Test from "./Pages/Test";
import PrivateRoute from "./PrivateRoute";
import AppLayout from "./AppLayout";
import ErrorPage from "./Pages/ErrorPage";
import ReportAnIssue from "./Pages/ReportAnIssue/ReportAnIssue";
import SecureLock from "./Pages/SecureLock/SecureLock";
import Map from "./Pages/Map/Map";
import History from "./Pages/History/History";
import { vehicleDeatilsLoader } from "./common/common";
import AddUser from "./Pages/AddUser/AddUser";
import Users from "./Pages/Users/Users";
import EditUser from "./Pages/EditUser/EditUser";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import MechanicPin from "./Pages/MechanicPin/MechanicPin";
import Keys from "./Pages/Keys/Keys";
import PinLogin from "./Pages/PinLogin/PinLogin";
import ActivityLog from "./Pages/ActivityLog/ActivityLog";


const router = createHashRouter([
    {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/pinLogin",
                element: <PinLogin />,
            },
            {
                path: "/forgotpassord",
                element: <ForgetPassword />,
            },
            {
                element: <PrivateRoute />,
                children: [
                    {
                        path: "/",
                        element: <Home />
                    },
                    {
                        path: "/adduser",
                        element: <AddUser />
                    },
                    {
                        path: "/users/edit/:id",
                        element: <EditUser />
                    }
                ]
            },
            {
                element: <AppLayout />,
                children: [
                    {
                        element: <PrivateRoute />,
                        children: [
                            {
                                path: "/secure-lock/:vehicleId",
                                element: <SecureLock />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children: [
                            {
                                path: "/map/:vehicleId",
                                element: <Map />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children: [
                            {
                                path: "/history/:vehicleId",
                                element: <History />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children: [
                            {
                                path: "/keys/:vehicleId",
                                element: <Keys />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children:[
                            {
                                path: "/users",
                                element: <Users />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children:[
                            {
                                path: "/pin",
                                element: <MechanicPin />
                            }
                        ]
                    },
                    {
                        element: <PrivateRoute />,
                        children:[
                            {
                                path: "/activity/:vehicleId",
                                element: <ActivityLog />
                            }
                        ]
                    }
                ]
            },
            {
                path: "/reportAnIssue",
                element: <ReportAnIssue />,
            },
            {
                path: "/test",
                element: <Test />,
            }
        ]
    }
]);

export default router;