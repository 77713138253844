import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: null, 
  historyAccess: null,
  userType: null,
  hasRfidCard: null
}

export const usersettingsSlice = createSlice({
  name: 'usersettings',
  initialState,
  reducers: {
    changeSettings: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeSettings } = usersettingsSlice.actions

export default usersettingsSlice.reducer