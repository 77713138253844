import React, { useEffect, useState } from 'react'
import HistoryGoogleMap from '../../Components/HistoryGoogleMap/HistoryGoogleMap'
import BottomNavigationBar from '../../Components/BottomNavigationBar/BottomNavigationBar'
import { NAME_UNAVAILABLE, vehicleDeatilsLoader } from '../../common/common';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';
import Loader from '../../Components/Loader/Loader';





function History() {
    let { vehicleId } = useParams();
    const [loading, setLoading] = useState(false);
    let [vehicleDetails, setVehicleDetail] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            let data = await vehicleDeatilsLoader({ vehicleId });
            setVehicleDetail(data)
            setLoading(false);
            if (data) {
                dispatch(changeTitle(data.VehicleName))
            } else {
                dispatch(changeTitle(NAME_UNAVAILABLE));
            }
        }
        loadData();
    }, []);

    return (
        <>
            <div className='mapContainer'>
                <div className='h-full'>
                    {
                        loading ? <Loader loading={loading} halfScreen={true}  heightClass={"h-full"}/> :
                            (
                                vehicleDetails ? <HistoryGoogleMap vehicleDetails={vehicleDetails} /> :
                                    <div className='center h-full'>Vehicle Not Found</div>
                            )
                    }
                </div>
            </div>
            <BottomNavigationBar />
        </>
    )
}

export default History