import React from 'react';

function ShowVehicleDetails({ vehicle }) {
  const maskDeviceNumber = (deviceNumber) => {
    if (deviceNumber.length <= 8) {
      return deviceNumber;
    }
    const start = deviceNumber.slice(0, 4);
    const end = deviceNumber.slice(-4);
    const maskedPart = deviceNumber.slice(4, -4).replace(/./g, '*');
    return `${start}${maskedPart}${end}`;
  };
  return (
    <>
      {
        vehicle &&
        <>
          <div className='text-2xl text-center mb-2 font-semibold'>Vehicle Details</div>
          <div className="bg-white p-2 rounded-sm text-sm">
            <div className="grid gap-4 grid-cols-11 border-b w-full p-1">
              <div className="col-span-4"><strong>Device Number</strong></div>
              <div className="col-span-7">{maskDeviceNumber(vehicle.ID)}</div>
            </div>
            <div className="grid gap-4 grid-cols-11 border-b w-full p-1">
              <div className="col-span-4"><strong>Address</strong></div>
              <div className="col-span-7">{vehicle.Address}</div>
            </div>
            <div className="grid gap-4 grid-cols-11 border-b w-full p-1">
              <div className="col-span-4"><strong>Battery Left</strong></div>
              <div className="col-span-7">{100 - vehicle.intBatteryUsed} %</div>
            </div>
            <div className="grid gap-4 grid-cols-11 w-full p-1">
              <div className="col-span-4"><strong>Power Source</strong></div>
              <div className="col-span-7">{vehicle.PowerSource}</div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default ShowVehicleDetails;
