import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VehicleList from '../../Components/VehicleList/VehicleList';
import WebRequest from '../../WebRequest';
import { updateVehiclesList } from '../../features/vehicles/vehiclesSlice';
import Header from '../../Components/Header/Header';
import Loader from '../../Components/Loader/Loader';




function Home() {
  const dispatch = useDispatch();
  const [vehiclesData, setVehiclesData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const loadVehiclesData =  async () => {
      setLoading(true);
      const vehiclesData = await WebRequest.GetVehiclesData();
      if(vehiclesData.success){
        setVehiclesData(vehiclesData.data.vehiclesData);
        dispatch(updateVehiclesList(vehiclesData.data.vehiclesData));
      } else{
        
      }
      setLoading(false);
    }
    loadVehiclesData();
  }, []);
  

  return (
    <>
      <Header title={"Your Vehicles"} menuOffCanvas={true} showUserName={true}/>
      <div className='p-5 pb-20' id='content'>
        {
          loading ? <Loader loading={true} halfScreen={true}/> : vehiclesData ?
            <VehicleList vehicles={vehiclesData}/> : "Failed to fetch vehicles."
       }
      </div>
    </>
  )
}

export default Home