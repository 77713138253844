import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';
import WebRequest from '../../WebRequest';
import { NAME_UNAVAILABLE, vehicleDeatilsLoader } from '../../common/common';
import { useParams } from 'react-router-dom';
import BottomNavigationBar from '../../Components/BottomNavigationBar/BottomNavigationBar';
import Loader from '../../Components/Loader/Loader';

function ActivityLog() {
    let { vehicleId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [activityLog, setActivityLog] = useState();
    let selectedVehicle = useRef();



    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            selectedVehicle.current = await vehicleDeatilsLoader({ vehicleId });
            setLoading(false);
            if (selectedVehicle.current) {
                dispatch(changeTitle(selectedVehicle.current.VehicleName))
            } else {
                dispatch(changeTitle(NAME_UNAVAILABLE));
                return;
            }
            getActivityLog();
        };
        loadData();
    }, []);

    const getActivityLog = async () => {
        setLoading(true);
        const apiResponse = await WebRequest.GetActivityLog({ vehicleId: selectedVehicle.current.ID });
        if (apiResponse.success && apiResponse.data.activityLogs) {
            setActivityLog(apiResponse.data.activityLogs);
            setError('');
        } else {
            setError(apiResponse.message)
        }
        setLoading(false);
    }

    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    }

    function formatTime(dateString) {
        const options = { hour: 'numeric', minute: '2-digit', hour12: true };
        return new Date(dateString).toLocaleTimeString('en-US', options);
    }

    return (
        <>
            <div className='px-2 innerContainer'>
                <div>
                    <div className="w-full max-w-6xl mx-auto p-4">
                        <div className="text-4xl mb-2 font-semibold text-center">Activity Log</div>
                        {!loading && activityLog && activityLog.length > 0 && (
                            <div className="flex flex-col justify-center divide-y divide-slate-200">
                                <div className="w-full max-w-3xl mx-auto py-8">

                                    <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-slate-500 before:via-slate-300 before:to-slate-200">
                                        {activityLog.map((log, index) => (
                                            <div className="relative mb-4" key={index}>
                                                <div className="md:flex items-center md:space-x-4 mb-3">
                                                    <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">

                                                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1">
                                                            {log.EventDescription == 'Unauthorized Card' && <span class="material-symbols-outlined">signal_disconnected</span>}
                                                            {log.EventDescription == 'Locked' && <span className="material-symbols-outlined">lock</span>}
                                                            {log.EventDescription == 'Unlocked' && <span className="material-symbols-outlined">lock_open_right</span>}
                                                        </div>
                                                        <div className='flex flex-col'>
                                                            <time className="text-sm font-medium md:w-28">{formatDate(log.DateTimeStamp)}</time>
                                                            <time className="text-sm font-medium md:w-28">{formatTime(log.DateTimeStamp)}</time>
                                                        </div>
                                                    </div>
                                                    <div className="text-slate-500 ml-14"><span className="text-gray-900 font-bold">{log.EventDescription}</span></div>
                                                </div>
                                                {log.GPSAddress && <div className="bg-white text-sm p-4 rounded border border-slate-200 text-gray-500 shadow ml-14 md:ml-44"><span>{log.GPSAddress}</span>.</div>}
                                            </div>
                                        ))}
                                    </div>
                                    
                                </div>
                                <div className="p-2 m-3 shadow-lg text-center rounded-lg bg-white text-sm font-semibold">
                                    Only last 20 activity logs will be visible here.
                                </div>
                            </div>
                        )}
                            
                        {loading && <Loader halfScreen={true} />}
                        {!loading && activityLog && activityLog.length === 0 && <p className='my-4 text-center'>No activity logs available.</p>}
                        {!loading && error && <p className='text-red-500 my-4 text-center'>Error while loading Activity logs: <br/><span>{error}</span></p>}
                    </div>
                </div>
            </div>
            <BottomNavigationBar />
        </>
    );
}

export default ActivityLog