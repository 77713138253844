import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  RouterProvider,
} from "react-router-dom";
import router from "./Router";
import { Toaster } from "react-hot-toast";
import { persistor, store } from './app/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { InstallPWA } from './Components/InstallPwa/InstallPwa';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <InstallPWA />
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </>
);


serviceWorkerRegistration.register();