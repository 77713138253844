import React, { useEffect, useRef, useState } from 'react'
import BottomNavigationBar from '../../Components/BottomNavigationBar/BottomNavigationBar'
import { useParams } from 'react-router-dom';
import WebRequest from '../../WebRequest';
import Loader from '../../Components/Loader/Loader';
import toast from 'react-hot-toast';
import CountdownTimer from '../../Components/CountdownTimer/CountdownTimer';
import { NAME_UNAVAILABLE, vehicleDeatilsLoader } from '../../common/common';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';

function RemoveRfidCard({ showConfirm, setShowConfirm, onConfirm }) {
  const [loading, setLoading] = useState(false);


  return (<>
    <Loader loading={loading} />
    <Dialog
      open={showConfirm}
      onClose={() => { setShowConfirm(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Remove RFID Key
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove the RFID Key?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setShowConfirm(false) }}>Cancel</Button>
        <Button onClick={() => { onConfirm() }} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  </>
  )
}





function Keys() {
  let { vehicleId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [assignedRfid, setAssignedRfid] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [attachingRfid, setAttachingRfid] = useState(false);
  let selectedVehicle = useRef();

  useEffect(() => {

    const loadData = async () => {
      setLoading(true);
      selectedVehicle.current = await vehicleDeatilsLoader({ vehicleId });
      setLoading(false);
      if (selectedVehicle.current) {
        dispatch(changeTitle(selectedVehicle.current.VehicleName))
      } else {
        dispatch(changeTitle(NAME_UNAVAILABLE));
        return;
      }
      getAssignedRfid();
    }
    loadData();
  }, []);


  const getAssignedRfid = async () => {
    setLoading(true);
    const response = await WebRequest.GetAssignedRfid({ vehicleId });
    if (response.success && response.data) {
      setAssignedRfid(response.data.assignedRfid);
    }
    setLoading(false);
  }


  const sendTcpCommand = async (deviceId, command) => {
    const data = { deviceId, command };
    const response = await WebRequest.SendCmdToDevice(data);
    return response;
  }

  const attachRfid = async () => {
    setLoading(true);
    const response = await sendTcpCommand(selectedVehicle.current.ID, "RFID-ASSIGN-START");
    setLoading(false);
    if (response.success && response.data.result == "ok") {
      setAttachingRfid(true);
    } else if (response.success) {
      toast.error("Failed to attach RFID key as server responded with status " + response.data.result);
    } else {
      toast.error(response.message);
    }
  }


  const removeRfid = async () => {
    setLoading(true);
    const response = await sendTcpCommand(selectedVehicle.current.ID, "RFID_REMOVE");
    setLoading(false);
    setShowConfirm(false);
    if (response.success && response.data.result == "ok") {
        getAssignedRfid();
    } else if (response.success) {
      toast.error("Failed to remove RFID key as server responded with status " + response.data.result);
    } else {
      toast.error(response.message);
    }
  }


  const checkStatusOfAttachment = async () => {
    const response = await sendTcpCommand(selectedVehicle.current.ID, "RFID-GET-STATUS");
    if (response.success) {
      if (response.data.result == "assigned") {
        toast.success("RFID card has been assigned successfully");
        setAttachingRfid(false);
        getAssignedRfid();
      }
    } else {
      setAttachingRfid(false);
      toast.error("Failed to process your request");
    }
  }

  const timerOver = () => {
    setAttachingRfid(false);
    toast.error("Time limit to attach RFID Card is over.");
  }
  const formatRfid = (rfid) => {
    // Inserting spaces after each character and non-breaking spaces after every four characters
    return rfid.replace(/.{4}/g, "$&\u00A0").replace(/(.)/g, "$1 ");
  };

  return (
    <>
      {loading ?

        <div className='innerContainer'>
          <Loader loading={true} halfScreen={true} />
        </div> :
        <>
          <div className='innerContainer p-4 text-center  max-w-5xl mx-auto'>
            <div className='text-4xl mb-2 font-semibold'>RFID Key</div>
            {
              assignedRfid ?
                <>
                  <div className='my-8 text-xl'>
                    <span className="block mb-2 text-lg font-medium text-gray-900">RFID Key in Use</span>
                    <div className='center gap-1'>
                      <div className="h-56 w-96 rounded-2xl bg-gradient-to-tr from-indigo-900 to-blue-700 group shadow-xl text-blue-200 tracking-wide font-semibold">
                        <div className="h-full w-full rounded-2xl dots-texture">
                          <div className="h-full flex flex-col justify-between">
                            <div className='text-right'>
                              <button onClick={() => { setShowConfirm(true) }} className="material-symbols-outlined p-2 m-2 bg-white text-gray-500 rounded-full border">
                                delete
                              </button>
                            </div>
                            <div className='flex-1'>
                              <span className="material-symbols-outlined pt-2 text-3xl text-blue-300">
                                rss_feed
                              </span>
                              <div className="text-2xl font-bold italic text-center text-blue-300">RFID Key</div>
                            </div>
                            <div className="bg-gray-500 bg-opacity-20 rounded-b-2xl border-t-2 border-indigo-600 text-gray-50 text-opacity-70">
                              <div className="text-sm p-4">{formatRfid(assignedRfid)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <RemoveRfidCard setShowConfirm={setShowConfirm} showConfirm={showConfirm} onConfirm={removeRfid} />
                  </div>
                </>
                :
                <>
                  <div className="my-8 p-4 text-lg text-gray-800 rounded-lg bg-gray-50 text-center font-semibold" role="alert">
                    Currently, no RFID Key has been attached to this vehicle.
                  </div>
                  <div className='center'>
                    <button
                      onClick={attachRfid}
                      type="button"
                      className="flex justify-center rounded-md bg-blue-600 px-7 py-3.5 font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-300"
                    >
                      Attach
                    </button>
                  </div>
                  <div className="my-8 p-4 text-gray-800 rounded-lg bg-gray-50 text-center" role="alert">
                    <div className='mb-2 text-lg font-bold'>Instructions to attach RFID Key</div>
                    <ol className="max-w-3xl space-y-1 text-gray-700 list-decimal text-left px-5">
                      <li>
                        Click on the blue <span className="font-semibold text-gray-900">Attach</span> button above.
                      </li>
                      <li>
                        A window will open asking you to tap RFID key on the RFID reader. RFID key must be tapped within one minute of opening.
                      </li>
                      <li>
                        Tap the RFID key on RFID reader. If successful, reader will beep once and a masked key ID will be displayed.
                      </li>
                    </ol>
                  </div>
                </>
            }
          </div>

          {
            attachingRfid &&
            <CountdownTimer timeLimit={59} onComplete={timerOver} onInterval={checkStatusOfAttachment} />
          }
        </>
      }

      <BottomNavigationBar />
    </>
  )
}

export default Keys