import React, { useEffect, useState } from 'react'
import Offcanvas from '../Offcanvas/Offcanvas';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function TopBar() {
    const title = useSelector((state) => state.title.value);
    const [isOffCanvasVisible, setOffCanvasVisible] = useState(false);
    const closeOffCanvas = () => {
        setOffCanvasVisible(false);
    };
    const openOffCanvas = () => {
        setOffCanvasVisible(true);
    };

    return (
        <>
            <header className='flex bg-white underline-shadow align-items px-1 py-2 items-center justify-between'>
                <div>
                    <Link to={"/"} className="material-symbols-outlined p-4">
                        arrow_back
                    </Link>
                </div>
                <div className='search-box flex-1'>
                    <div className="text-center text-xl font-medium text-gray-900 flex-1 px-5">
                        {title}
                    </div>
                </div>
                <div>
                    <button type='button' onClick={openOffCanvas} className="material-symbols-outlined p-4">menu</button>
                </div>
            </header>
            <nav>
                <Offcanvas offCanvasSate={{ isOffCanvasVisible, closeOffCanvas }} />
            </nav>
        </>
    )
}

export default TopBar