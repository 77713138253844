import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OffCanvas from '../Offcanvas/Offcanvas';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';

function Header({ title, menuOffCanvas, showUserName }) {
    const navigate = useNavigate();
    const [isOffCanvasVisible, setOffCanvasVisible] = useState(false);
    const usersettings = useSelector((state) => state.userSettings.value);

    const closeOffCanvas = () => {
        setOffCanvasVisible(false);
    };
    const openOffCanvas = () => {
        setOffCanvasVisible(true);
    };
    return (
        <header className='w-screen flex bg-white underline-shadow align-items px-1 py-2 items-center justify-between'>
            <div className='search-box flex-1'>
                <div className="text-2xl font-medium text-gray-900 flex-1  max-w-xl">
                    {showUserName ? <>
                        {usersettings && usersettings.username &&
                            <div className='py-2 px-4'>
                                <div className='flex items-center'>
                                    {
                                        usersettings.username == "Mechanic" ?
                                            <>
                                                <div className='h-auto w-8 mr-4'>
                                                    <img src='/resources/images/mechanic.svg' className='mr-2' />
                                                </div>

                                                <span>Guest</span>
                                            </>
                                            :
                                            <>
                                                <div className='mr-4'>
                                                    <Avatar sx={{ bgcolor: "#DC2626" }}>{usersettings.username[0].toUpperCase()}</Avatar>
                                                </div>

                                                <span className='truncate max-[360px]:w-36 w-56 sm:w-auto '>{usersettings.username}</span>
                                            </>
                                    }
                                </div>
                            </div>
                        }
                    </> : <span className='pl-4'>{title}</span>}
                </div>
            </div>
            {
                menuOffCanvas ? <button type='button' onClick={openOffCanvas} className="material-symbols-outlined p-4">menu</button> : <button onClick={() => navigate(-1)}>
                    <span className="material-symbols-outlined p-4">
                        close
                    </span>
                </button>
            }
            <nav>
                <OffCanvas offCanvasSate={{ isOffCanvasVisible, closeOffCanvas }} />
            </nav>
        </header>
    )
}

export default Header