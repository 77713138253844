import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Avatar, Button, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch, TextField } from '@mui/material'
import WebRequest from '../../WebRequest';
import toast from 'react-hot-toast';
import Loader from '../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

function AddUser() {
    const [allowHistoryAccess, setAllowHistoryAccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const createUser = async () => {
        if (!userName.trim()) {
            toast.error("Please enter a valid username");
            return;
        }

        if (!/^\S+@\S+\.\S+$/.test(email)) {
            toast.error("Please enter a valid email address");
            return;
        }
        setLoading(true);
        const response = await WebRequest.AddUser({
            email, userName, allowHistoryAccess
        });
        if (response.success) {
            toast.success(response.message);
            setEmail('');
            setUserName('');
            setAllowHistoryAccess(false);
        } else {
            toast.error(response.message);
        }
        setLoading(false);
    }

    return (
        <>
            <div className='flex flex-col h-full sm:items-center dvh-full'>
                <Loader loading={loading} />
                <Header title={"Add User"} />
                <div className='flex flex-col flex-1 md:w-[576px] m-4'>
                    <div className='space-y-6 rounded-lg'>
                        <br />
                        <div className='grid gap-y-4 items-center'>
                            <div>
                                <label htmlFor="email" className="font-medium block mb-2 leading-6 text-gray-900">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    onPaste={(e) => setEmail(e.target.value)}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    className="w-64 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div>
                                <label htmlFor="username" className="block mb-2 font-medium leading-6 text-gray-900">
                                    Username
                                </label>
                                <input
                                    id="username"
                                    name="username"
                                    type="username"
                                    required
                                    onPaste={(e) => setUserName(e.target.value)}
                                    value={userName}
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    className="w-64 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div>
                                <label htmlFor="allowHistoryAccess" className="mr-2 inline-block my-2 font-medium leading-6 text-gray-900">
                                    Allow History Access
                                </label>
                                <Switch
                                    edge="end"
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-history',
                                    }}
                                    checked={allowHistoryAccess}
                                    onChange={() => { setAllowHistoryAccess(!allowHistoryAccess) }}
                                />
                            </div>
                        </div>
                        <div className='flex gap-6'>
                            <Button type='submit' variant="contained" onClick={createUser}>Add User</Button>
                            <Button type='button' variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUser;