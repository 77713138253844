import React, { useEffect, useState } from 'react';
import PWAPrompt from 'react-ios-pwa-prompt'
import { Dialog, Drawer } from '@mui/material';
import { isIOS, isPwa } from '../../common/common';

export const InstallPWA = () => {
  const [installDialogOpen, setInstallDialogOpen] = useState(true);
  const [webInstallPrompt, setWebInstallPromptEvent] = useState();
  const [showInstallIntructions, setShowInstallIntructions] = useState(false);


  useEffect(() => {
    const beforeInstallPromptHandler = event => {
      event.preventDefault();
      // store the event for later use
      setWebInstallPromptEvent(event);
    };

    const handleStandaloneChange = (event) => {
        window.location.reload();
    };

    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    mediaQuery.addEventListener('change', handleStandaloneChange);

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    if(!isPwa()){
      try{
        if(window.opener.location.origin == window.location.origin){
          window.close();
        }
      }catch(e){}
    }

    return () => { 
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      mediaQuery.removeEventListener('change', handleStandaloneChange);
    }
  }, []);


  const openInApp = () => {
      setInstallDialogOpen(false);
      setShowInstallIntructions(true); 
      
      if(!isIOS()){
        window.open(window.location.href, "_blank")
      }
  }


  const askToInstall = async () =>{
    webInstallPrompt.prompt();
    const { outcome } = await webInstallPrompt.userChoice;
    if (outcome === 'accepted') {
      setWebInstallPromptEvent(null);
    } 
  }


  return (
    <>
      {
        isIOS() && <PWAPrompt promptOnVisit={1} timesToShow={99} copyClosePrompt="Close" permanentlyHideOnDismiss={false} copyBody="This website has app functionality. Install it as a app for better user experience." />
      }
        {webInstallPrompt && (
        <Dialog open={installDialogOpen}>
          <div id='webInstallPrompt' className='bottom-tab p-2 center gap-4 items-center bg-gray-200 border'>
            <div className='mr-8'>
              <img src="resources/icons/android-chrome-512x512.png" className='size-10 rounded-xl' alt='logo' />
            </div>
            <button className='flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-300' onClick={askToInstall}>Install App</button>
            <button className='text-sm text-blue-600 hover:underline' onClick={() => setInstallDialogOpen(false)}>Cancel</button>
          </div>
        </Dialog>
      )}

      {
        !isIOS() && !isPwa() && !webInstallPrompt && (
          <>
            <Dialog open={installDialogOpen}>
              <div id='webInstallPrompt' className='bottom-tab p-2 center gap-4 items-center bg-gray-200 border'>
                <div className='mr-8'>
                  <img src="resources/icons/android-chrome-512x512.png" className='size-10 rounded-xl' alt='logo' />
                </div>
                <button className='flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-300' onClick={openInApp}>Open In App</button>
                <button className='text-sm text-blue-600 hover:underline' onClick={() => setInstallDialogOpen(false)}>Cancel</button>
              </div>
            </Dialog>
            <Drawer open={showInstallIntructions} anchor='bottom'>
              <div>
                <div className='border-b flex items-center justify-between'>
                  <span className='text-xl p-4 font-bold'>Install App</span>
                  <button className=' text-blue-600 hover:underline p-4' onClick={() => setShowInstallIntructions(false)}>Cancel</button>
                </div>
                <div className='m-4'>
                  Follow the below instructions to install this app on your mobile:
                  <hr className='mt-4' />
                  <div className='flex items-center my-4'>
                    <span className="material-symbols-outlined w-12 text-4xl">
                      more_vert
                    </span>
                    <span className='w-8  text-xl'>1)</span>
                    <span className='flex-1'>Tap on the 3 verticle dots, to open the browser menu.</span>
                  </div>
                  <div className='flex items-center my-4'>
                    <span className="material-symbols-outlined w-12 text-4xl">
                      install_mobile
                    </span>
                    <span className='w-8  text-xl'>2)</span>
                    <span className='flex-1'>Choose 'Install App' Option.</span>
                  </div>
                  <div className='border-t py-4'>
                    <b className='mr-1'>Note: </b> If you have already installed the app, open the Apps screen and look for this icon: <img src="resources/icons/android-chrome-192x192.png" className='inline size-8 ml-2' alt='logo' /> 
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )
      }
    </>
  );
};
