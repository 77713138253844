import React from 'react'
import './Toast.css'

function Toast({message, toastStyle, containerStyle , onClose}) {
  return (
    <div className='amr-toastContainer'  style={containerStyle}>
        <div className='toast-messageContainer' style={toastStyle}>
            <div className='toast-message'>{message}</div>
            { onClose &&
                <button onClick={onClose} className="material-symbols-outlined amr-btnToastClose" type='button'>
                    close
                </button>
            }
        </div>
    </div>
  )
}

export default Toast