import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import WebRequest from '../../WebRequest';
import Loader from '../../Components/Loader/Loader';
import DeleteUser from '../../Components/DeleteUser/DeleteUser';

function Users() {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [userToDelete, setUserToDelete] = useState(false);

    useEffect(() => {
        dispatch(changeTitle("Users"));
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);
        const response = await WebRequest.GetAllUsers();
        if(response.success) {
            setUsers(response.data.userList);
            setErrorMessage('');
        } else{
            setErrorMessage(response.message);
        }
        setLoading(false);
    }

    const confirmDelete = (user) => {
        setShowDeleteConfirm(true);
        setUserToDelete(user);
    }

    return (
        <div>
            <div className='p-4 h-20 flex justify-between items-center'>
                <h1 className="flex items-center text-3xl font-bold">All Users</h1>
                <Link to={`/adduser`} className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 ">
                    <span className="me-2 material-symbols-outlined text-lg">
                        person_add
                    </span>
                    Add User
                </Link>
            </div>
            <div style={{height: 'calc(100dvh - 72px - 80px - 8px )'}} className='overflow-auto'>
                {loading ? <Loader halfScreen={true}/>:
                    errorMessage !== '' ? <div className='p-4 h-full text-red-600 center text-xl'>{errorMessage}</div>:
                    <ul role="list"  className="divide-y divide-gray-200">
                        {users.map((user, index) => (
                            <li key={index} className="py-4 bg-white p-4 mx-4 ">
                                <div className="flex items-center gap-4">
                                    <div className="flex-shrink-0">
                                        <Avatar sx={{ bgcolor: "#DC2626" }}>{user.vcUserName[0].toUpperCase()}</Avatar>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="font-medium text-lg text-gray-900 truncate">
                                            {user.vcUserName}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate">
                                            {user.vcEmail}
                                        </p>
                                    </div>
                                    <div className='center gap-2'>
                                        {
                                            user.bitUpdatePossible == 0 &&
                                            <>
                                                <Link to={`/users/edit/${user.idId}?partialEdit=true`} className="material-symbols-outlined bg-blue-100 p-2 rounded-2xl">
                                                    edit
                                                </Link>
                                            </>
                                        }
                                        {
                                            user.bitUpdatePossible == 1 &&
                                            <>
                                                <Link to={`/users/edit/${user.idId}`} className="material-symbols-outlined bg-blue-100 p-2 rounded-2xl">
                                                    edit
                                                </Link>
                                                <button onClick={()=>{confirmDelete(user)}} className="material-symbols-outlined bg-blue-100 p-2 rounded-2xl">
                                                    delete
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
            <DeleteUser onDeleteCallback={getUsers} user={userToDelete} setShowDeleteConfirm={setShowDeleteConfirm} showDeleteConfirm={showDeleteConfirm}/>
        </div>
    );
}

export default Users;
