import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader';
import WebRequest from '../../WebRequest';
import toast from 'react-hot-toast';

function ForgetPassword() {
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = useState(false);
    const resetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await WebRequest.ResetPassword({
            email
        });
        if (response.success){
            setEmail('');
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
        setLoading(false);
    }

    return (
        <div className='dvh-full flex flex-col px-6 py-12 lg:px-8 gap-4'>
           <div className="flex flex-1 flex-col justify-center">
                <div>
                    <div>
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 ">Forgot password?</h1>
                            <p className="mt-2 text-sm text-gray-600 ">
                                Remember your password? {" "}
                                <Link className="text-blue-600 decoration-2 hover:underline font-medium" to='/login'>
                                    Login here
                                </Link>
                            </p>
                        </div>

                        <div className="mt-5 max-w-sm mx-auto">
                            {loading ? (<Loader halfScreen={true} loading={loading} />) :
                                (<form onSubmit={resetPassword}>
                                    <div className="grid gap-y-6">
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Enter your registered email
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm shadow-sm"
                                                    autoComplete="email"
                                                    required
                                                    onPaste={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    aria-describedby="email-error" />
                                            </div>
                                            <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                                        </div>
                                        <button
                                            type="submit"
                                            className="flex mx-auto w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-300"
                                        >
                                            Reset password
                                        </button>
                                    </div>
                                </form>)
                            }
                        </div>
                    </div>
                </div>

                <p className="mt-4 text-center text-sm text-gray-500">
                    Facing a problem?{' '}
                    <Link to={'/reportAnIssue'} className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
                        Report an Issue
                    </Link>
                </p>
            </div>
            <div className='text-center'>
                <h1 className='text-2xl text-blue-600 font-semibold'>StopTheft</h1>
                <p>By IIMSWISS Corp.</p>
            </div>
        </div>
    )
}

export default ForgetPassword