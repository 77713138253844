import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { Avatar, Button, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Switch, TextField } from '@mui/material'
import WebRequest from '../../WebRequest';
import toast from 'react-hot-toast';
import Loader from '../../Components/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function EditUser() {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const partialEdit = queryParams.get('partialEdit');
    const [allowHistoryAccess, setAllowHistoryAccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getUserDetail = async () => {
            setInitialLoading(true);
            const response = await WebRequest.GetUserDetail({ id });
            if (response.success) {
                setAllowHistoryAccess(response.data.user.bitHistoryAccess);
                setUserName(response.data.user.vcUserName);
                setEmail(response.data.user.vcEmail);
            }
            setInitialLoading(false);
        }
        getUserDetail();
    }, []);

    const updateUser = async () => {
        if (!userName.trim()) {
            toast.error("Please enter a valid username");
            return;
        }

        if (!/^\S+@\S+\.\S+$/.test(email)) {
            toast.error("Please enter a valid email address");
            return;
        }
        setLoading(true);
        const response = await WebRequest.EditUser({
            email, userName, allowHistoryAccess, id
        });
        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
        setLoading(false);
    }

    return (
        <>
            <div className='flex flex-col h-full md:items-center dvh-full'>
                <Loader loading={loading} />
                <Header title={"Edit User"} />
                {initialLoading ? <Loader loading={true} halfScreen={true} /> :
                    <div className='flex flex-col flex-1 p-4 md:w-[576px]'>
                        <div className='space-y-6 sm:p-8 rounded-lg'>
                            <br />
                            <div className='grid gap-y-4 items-center'>
                                <div>
                                    <label htmlFor="email" className="block font-medium mb-2 leading-6 text-gray-900">
                                        Email address
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        disabled={partialEdit == "true"}
                                        required
                                        onPaste={(e) => setEmail(e.target.value)}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        className="rounded-md w-64 border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="username" className="block mb-2 font-medium leading-6 text-gray-900">
                                        Username
                                    </label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="username"
                                        required
                                        onPaste={(e) => setUserName(e.target.value)}
                                        value={userName}
                                        onChange={(e) => {
                                            setUserName(e.target.value);
                                        }}
                                        className="w-64 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="allowHistoryAccess" className="mr-2 inline-block my-2 font-medium leading-6 text-gray-900">
                                        Allow History Access
                                    </label>
                                    <Switch
                                        edge="end"
                                        inputProps={{
                                            'aria-labelledby': 'switch-list-label-history',
                                        }}
                                        checked={allowHistoryAccess}
                                        disabled={partialEdit == "true"}
                                        onChange={() => { setAllowHistoryAccess(!allowHistoryAccess) }}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-6'>
                                <Button type='submit' variant="contained" onClick={updateUser}>Update</Button>
                                <Button type='button' variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default EditUser;