import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import './BottomNavigationBar.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { changeSettings } from '../../features/usersettings/usersettingsSlice';
import WebRequest from '../../WebRequest';

function BottomNavigationBar() {
    const dispatch = useDispatch();
    let { vehicleId } = useParams();
    const usersettings = useSelector((state) => state.userSettings.value);

    useEffect(() => {
        const getUserSettings = async () => {
            const response = await WebRequest.GetUserSettings();
            if (response.success) {
                dispatch(changeSettings({ historyAccess: response.data.historyAccess, username: response.data.username, userType: response.data.userType, hasRfidCard: response.data.hasRfidCard }))
            }
        }

        if (!usersettings || usersettings.historyAccess === null || usersettings.hasRfidCard === null) {
            getUserSettings();
        }
    }, [])


    return (
        <div className="bottomTab border-t bg-white">
            <div className="h-full border-gray-200 max-w-lg mx-auto">
                <div className="flex justify-evenly h-full font-medium">
                    <NavLink to={`/secure-lock/${vehicleId}`} className="navgation-link">
                        <span className="material-symbols-outlined">
                            assured_workload
                        </span>
                        <span className="bn-link-text">Home</span>
                    </NavLink>
                    {usersettings && usersettings.hasRfidCard &&
                        <NavLink to={`/keys/${vehicleId}`} className="navgation-link">
                            <span className="material-symbols-outlined">
                                passkey
                            </span>
                            <span className="bn-link-text">Keys</span>
                        </NavLink>
                    }

                    <NavLink to={`/map/${vehicleId}`} className="navgation-link">
                        <span className="material-symbols-outlined">
                            near_me
                        </span>
                        <span className="bn-link-text">Map</span>
                    </NavLink>
                    {usersettings && usersettings.historyAccess && <NavLink to={`/history/${vehicleId}`} className="navgation-link">
                        <span className="material-symbols-outlined">
                            history
                        </span>
                        <span className="bn-link-text">History</span>
                    </NavLink>}
                    <NavLink to={`/activity/${vehicleId}`} className="navgation-link">
                        <span className="material-symbols-outlined">
                            summarize
                        </span>
                        <span className="bn-link-text">Log</span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default BottomNavigationBar