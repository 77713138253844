import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "StopTheft",
}

export const titleSlice = createSlice({
  name: 'title',
  initialState,
  reducers: {
    changeTitle: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeTitle } = titleSlice.actions

export default titleSlice.reducer