import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";


const CountdownTimer = ({ timeLimit, onComplete, onInterval }) => {
    const timeLeft = useRef(timeLimit);
    const [timerInterval, setTimerInterval] = useState(null);
    const [leftTime, setLeftTime] = useState(timeLimit);

    useEffect(() => {
        const interval = startTimer(); // Start the timer and store the interval ID
        return () => {
            clearInterval(interval); // Clear the interval when the component unmounts
        };
    }, []);
    
    

    const startTimer = () => {
        const interval = setInterval(() => {
            timeLeft.current = timeLeft.current - 1;
            setLeftTime(timeLeft.current);
            onInterval();
            if (timeLeft.current === 0) {
                onTimesUp();
                clearInterval(interval);
            }
        }, 1000);
        return interval; // Return the interval ID
    };
    

    const onTimesUp = () => {
        clearInterval(timerInterval);
        onComplete();
    };

    const coundownFormatTime = time => {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;
        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        if (minutes < 0 || seconds < 0) {
            return "0:00"
        }

        return `${minutes}:${seconds}`;
    };

    return (
        <div className="center h-screen">
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <h3 className="font-bold text-xl p-4">
                    Tap and Hold your RFID Card
                </h3>
                <DialogContent className="center my-4">
                    <div className="border-2 border-blue-200 rounded-full">
                        <div className="border-8 border-blue-500 size-60 rounded-full center">
                            <span className="text-5xl">{coundownFormatTime(leftTime)}</span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

        </div>
    );
};

export default CountdownTimer;
