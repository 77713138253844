import React, { useEffect, useState } from 'react'
import BottomNavigationBar from '../../Components/BottomNavigationBar/BottomNavigationBar'
import GoogleMap from '../../Components/GoogleMap/GoogleMap'
import './Map.css'
import { useLoaderData, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeTitle } from '../../features/title/titleSlice';
import { NAME_UNAVAILABLE, vehicleDeatilsLoader } from '../../common/common';
import Loader from '../../Components/Loader/Loader';

function Map() {
    let { vehicleId } = useParams();
    const [loading, setLoading] = useState(false);
    let [initialVehicleDetail, setInitialVehicleDetail] = useState();

    const dispatch = useDispatch();
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            let data = await vehicleDeatilsLoader({ vehicleId });
            setInitialVehicleDetail(data)
            setLoading(false);
            if(data){
                dispatch(changeTitle(data.VehicleName))
            } else{
                dispatch(changeTitle(NAME_UNAVAILABLE));
            }
        }
        loadData();
    }, []);



    return (
        <>
            <div className='mapContainer'>
                <div className='h-full'>
                    {
                        loading ? <Loader loading={loading} halfScreen={true} heightClass={"h-full"}/>:
                        (
                            initialVehicleDetail ? <GoogleMap initialVehicleDetail={initialVehicleDetail} /> : 
                            <div className='center h-full'>Vehicle Not Found</div>
                        )
                    }
                </div>
            </div>
            <BottomNavigationBar />
        </>
    )
}

export default Map